.footer {
  margin: 2em auto;
  max-width: calc(980px - 2em);
  width: calc(100% - 2em);
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: flex-start;
}

.logo {
  flex: 0 0 40px;
  padding-left: .3em;
}

.logo img {
  width: 28px;
  height: 248x;
}

.nav {
  font-size: .8em;
  flex: 1;
  color: rgba(255, 255, 255, .8);
}

.nav nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.nav a {
  margin-right: 1em;
  white-space: nowrap;
}

.social {
  margin: .5em 0;
  flex: 0 1 200px;
}

.social ul {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social li {
  list-style-type: none;
  padding: 0;
}

.social li img {
  width: 24px;
  height: 24px;
}