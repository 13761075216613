.comparison {
  position: relative;
  background: #f4f4f4;
  color: #111;
  overflow: hidden;
}

.comparison section {
  margin: 0 auto;
  padding: 1em 1.5em;
  box-sizing: border-box;
  max-width: 980px;
  box-shadow: 0 60px 100px rgba(0,0,0,.1);
  position: relative;
}

.comparison div {
  padding: 1em;
  margin: 0 -1.5em 0 -2em;
  max-width: 100vw;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.comparison table {
  font-size: .875em;
  border-spacing: 0;
  min-width: 80%;
  margin: 0 auto;
}

.comparison thead th svg {
  margin: .5em 0;
}

.comparison thead th:not(.gsa) {
  padding-top: 60px;
}

.comparison tr > *:first-child {
  position: sticky;
  background: #f4f4f4;
  text-align: left;
  left: 0;
  font-weight: normal;
  z-index: 2;
  box-shadow: -10px 0 0 10px #f4f4f4;
}

.comparison thead tr > *:first-child + * {
  font-weight: 300;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: AltoPro, sans-serif;
}

.comparison tr > *:first-child + * {
  background-color: #252627;
  color: #fff;
  box-shadow: 2px 4px 10px rgba(0,0,0,.2);
  font-weight: bold;
  border-color: transparent !important;
}

.comparison thead tr > *,
.comparison tbody tr:last-child > * {
  border-bottom: 1px solid #bca36c;
}

.comparison thead tr > *:not(:first-child) {
  border-radius: 5px 5px 0 0;
}

.comparison tfoot tr td:first-of-type {
  border-radius: 0 0 5px 5px;
}

.comparison td, .comparison th {
  text-align: center;
  padding: .8em;
}

.comparison td {
  background-color: #eee;
}

.comparison thead th {
  min-width: 118px;
}

.small {
  display: block;
  font-size: .85em;
  font-weight: normal;
  max-width: 140px;
  margin: 1em auto .3em;
}

.yes {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 12%;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 236.988 236.988"><polygon points="198.098,24.326 87.543,134.881 38.891,86.229 0,125.121 87.543,212.662 236.988,63.217" fill="%23bca36c" /></svg>');
}

.no {
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8.5%;
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.939 95.939"><path fill="%23999999" d="M62.819,47.97l32.533-32.534c0.781-0.781,0.781-2.047,0-2.828L83.333,0.586C82.958,0.211,82.448,0,81.919,0 c-0.53,0-1.039,0.211-1.414,0.586L47.97,33.121L15.435,0.586c-0.75-0.75-2.078-0.75-2.828,0L0.587,12.608 c-0.781,0.781-0.781,2.047,0,2.828L33.121,47.97L0.587,80.504c-0.781,0.781-0.781,2.047,0,2.828l12.02,12.021 c0.375,0.375,0.884,0.586,1.414,0.586c0.53,0,1.039-0.211,1.414-0.586L47.97,62.818l32.535,32.535 c0.375,0.375,0.884,0.586,1.414,0.586c0.529,0,1.039-0.211,1.414-0.586l12.02-12.021c0.781-0.781,0.781-2.048,0-2.828L62.819,47.97 z" /></svg>');
}